import appCategories from './categories';

const domain = process.env.NEXT_PUBLIC__PLATFORM_URL || 'https://mindlesswriter.com';

export const defaultSeo = {
    name: 'MindLess Writer',
    title: 'MindLess Writer - Write SEO optimized Content that ranks on Google',
    description:
        'Beat the organic SEO competition with MindLess Writer optimized content. No more guessing, just results.',
    category: 'SEO tools',
    pageUrl: domain,
    logoUrl: `${domain}/logo.png`,
    logoWidth: 256,
    logoHeight: 102,
    imageUrl: `${domain}/facebook-cover.png`,
    imageWidth: 1200,
    imageHeight: 630,
    social: [],
    twitter: undefined,
    alternateName: ['MindLess Writer', 'MW AI', 'MindLess AI', 'MindLess Writer AI'],
    contactEmail: 'hello@mindlesswriter.com',
    rssFeed: [{ url: '/rss/article-rss.xml' }],
    favicons: {
        icon: [
            { sizes: '32x32', url: '/favicon-32x32.png', type: 'image/png' },
            { sizes: '16x16', url: '/favicon-16x16.png', type: 'image/png' },
        ],
        apple: [{ url: '/apple-touch-icon.png' }],
    },
};

export const blogAuthor = {
    name: 'MindLess Writer Team',
    image: `${domain}/logo192.png`,
};

export const footerLinks = [
    {
        title: 'Company',
        links: [
            { title: 'Homepage', url: '/' },
            { title: 'Pricing', url: '/pricing' },
            { title: 'About Us', url: '/about' },
            { title: 'Contact Us', url: '/contact' },
        ],
    },
    // {
    //     title: "Product",
    //     links: [
    //         { title: 'MindLess AI', url: '/ai' },
    //         {
    //             title: 'Content Editor',
    //             url: '/content-editor',
    //         },
    //         {
    //             title: 'Project Audit',
    //             url: '/project-audit',
    //         },
    //         {
    //             title: 'Keyword Research',
    //             url: '/keyword-research',
    //         },
    //         // {
    //         //     title: 'Free Outline Generator',
    //         //     url: '/free-outline-generator',
    //         // },
    //         // {
    //         //     title: 'Free SERP Analyzer',
    //         //     url: '/free-serp-checker',
    //         // },
    //         // {
    //         //     title: 'Free SEO Sitemap Finder',
    //         //     url: '/free-seo-sitemap-finder',
    //         // }
    //     ],
    // },
    {
        title: 'Resources',
        links: appCategories.subjects.map((subj: any) => ({
            title: subj.subject,
            url: `/${subj.urlSlug}/blog`,
        })),
    },
];
