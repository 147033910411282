import { useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import { Divider, Drawer, Button, Link, Logo, Avatar, Box, Typography } from "@repo/ui"
import CloseIcon from "@mui/icons-material/Close"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { useRouter } from "next/navigation"
import { goToMenuItem } from "@repo/utils/menuUtils"
import Image from "next/image"
import { userMenuItems } from "@/constants/topMenuItems"
import RedirectBackLinkWrapper from "@/components/molecules/RedirectBackLinkWrapper"
import { defaultSeo } from "@/constants/seo-constants"
import RegisterActionButton from "@/app/(public)/(payment)/pricing/RegisterActionButton"
import { topmenuItems } from "../.."
import TopMenuContentItem from "./TopMenuContentItem"
import styles from "./TopMenuMobile.module.scss"

const TopMenuMobile = ({ userLoggedIn, user, setUserState }) => {
  const [open, setOpen] = useState(false)
  const [screenMode, setScreenMode] = useState("start")
  const [selectedContent, setSelectedContent] = useState(null)

  const { push } = useRouter()

  const onDrawerClose = () => {
    setOpen(false)
  }
  const onDrawerOpen = () => {
    setOpen(true)
  }

  const contentItems = topmenuItems.map((item) => {
    return {
      label: item.title,
      icon: item.icon,
      onClick: item.columns ? () => {
        setScreenMode(item.title.toLowerCase())
        setSelectedContent(item)
      } : undefined,
      route: item.url,
      columns: item.columns,
    }
  })

  const contentItemsMapper = ({ label, icon, onClick, route, target }) => {
    if (route) {
      return (
        <Link
          key={label}
          to={route}
          className={styles["top-menu-content-item-link"]}
          target={target}
        >
          <TopMenuContentItem label={label} icon={icon} onClick={onClick} hasRoute={Boolean(route)} />
        </Link>
      )
    }

    return <TopMenuContentItem key={label} label={label} icon={icon} onClick={onClick} />
  }

  const onResetModeClick = () => {
    setScreenMode("start")
  }

  const userMenuItemsMapper = ({ route, onClick, icon, label, divider }) => {
    return (
      <div key={label}>
        <Button
          padding="0.5rem 0"
          variant="text"
          onClick={() => goToMenuItem(onClick, push, route, user, setUserState, setScreenMode)}
        >
          <Box style={{ minWidth: 32 }}>{icon}</Box>
          {label}
        </Button>

        {divider && <Divider />}
      </div>
    )
  }

  return (
    <div>
      <div className={styles["hamburger-icon"]}>
        <Button
          edge="start"
          aria-label="open drawer"
          style={{
            color: "#fff",
          }}
          onClick={onDrawerOpen}
          variant="text"
        >
          <MenuIcon fontSize="large" />
        </Button>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={onDrawerClose}
        onOpen={onDrawerOpen}
        header={
          <>
            <Box style={{ display: "flex", alignItems: "center" }}>
              {screenMode !== "start" ? (
                <ChevronLeftIcon
                  onClick={onResetModeClick}
                  style={{ color: "#fff", width: 32 }}
                  className={styles["menu-item-icon"]}
                />
              ) : (
                <div style={{ width: 32 }}></div>
              )}

              <Link to="/" style={{ flex: 2, display: "flex", justifyContent: "center" }}>
                <Logo width={150} height={60} Image={Image} companyName={defaultSeo.name} />
              </Link>

              <Button onClick={onDrawerClose} variant="text">
                <CloseIcon style={{ color: "#fff" }} />
              </Button>
            </Box>
            <Divider style={{ marginTop: "0.5rem", backgroundColor: "#fff" }} />
          </>
        }
      >
        <Box
          backgroundColor="var(--background-color)"
          padding="1rem"
          margin="0 0 2rem 0"
        >
          {screenMode === "start" ? (
            <Box style={{ marginTop: "2rem", flex: 1 }}>
              <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                Main Menu
              </Typography>

              {contentItems.map(contentItemsMapper)}
            </Box>
          ) : (
            <Box style={{ marginTop: "2rem" }}>
              <Box display="flex" style={{ gap: ".5rem" }}>
                {Boolean(selectedContent?.icon) && selectedContent?.icon}

                <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                  {selectedContent?.title}
                </Typography>
              </Box>

              {selectedContent?.columns?.map(({ title: header, items }) => (
                <Box>
                  {header && (
                    <Typography
                      margin="2rem 0 0 0"
                      fontWeight="bold"
                      style={{ borderBottom: "1px solid var(--secondary-color)" }}
                      padding=".5rem 0"
                    >
                      {header.toUpperCase()}
                    </Typography>
                  )}

                  {items.map(({ title, icon, url }) => (
                    contentItemsMapper({
                      label: title,
                      icon,
                      route: url,
                    })
                  ))}
                </Box>
              ))}
            </Box>
          )}

          {userLoggedIn ? (
            <div>
              {screenMode === "start" &&
                contentItemsMapper({
                  label: user?.email,
                  icon: <Avatar src={user.profilePicture} alt={user.fullName} />,
                  onClick: () => {
                    setScreenMode("profile")
                  },
                })}

              {screenMode === "profile" && (
                <Box style={{ marginTop: "2rem" }}>
                  <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
                    {user.fullName}
                  </Typography>

                  {userMenuItems.map(userMenuItemsMapper)}
                </Box>
              )}
            </div>
          ) : (
            <div className={styles["login-buttons"]}>
              <RedirectBackLinkWrapper url="/login" >
                <Button>Login</Button>
              </RedirectBackLinkWrapper>

              <RegisterActionButton
                color="secondary"
                marginTop="0"
                text="Get 2 Free Articles"
                nextUrl="/projects"
              />
            </div>
          )}
        </Box>
      </Drawer>
    </div >
  )
}

export default TopMenuMobile
