'use client';

import { Button } from '@repo/ui';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';
import RedirectBackLinkWrapper from '@/components/molecules/RedirectBackLinkWrapper';

export default function RegisterActionButton({
    marginTop = 16,
    mainButton = false,
    variant = 'contained',
    text = 'Sign Up',
    nextUrl,
    color,
}: {
    marginTop?: number | string;
    mainButton?: boolean;
    variant?: 'contained' | 'outlined' | 'text' | 'cta';
    text?: string;
    nextUrl?: string;
    color?: 'primary' | 'secondary' | 'default' | 'accent';
}) {
    const { user } = useUserStore();

    if (user.id) {
        return null;
    }

    const parsedVariant = mainButton ? 'cta' : variant;
    return (
        <RedirectBackLinkWrapper url="/register" style={{ marginTop }} nextUrl={nextUrl}>
            <Button variant={parsedVariant} endIcon={<ArrowForwardIcon />} color={color}>
                {text}
            </Button>
        </RedirectBackLinkWrapper>
    );
}
