import type { ArticlePrefix } from '@repo/utils/articleUtils';

const subjects = [
    {
        id: 1,
        imageUrl: '/logo192.png',
        urlSlug: 'general-seo',
        subject: 'General SEO',
        description: `Learn SEO basics with our guides to improve your site's ranking. Let's explore our top search engine optimization strategies.`,
        subTopics: [
            'SEO Fundamentals',
            'SEO Ranking Factors',
            'Keyword Research Process',
            'Search Engine Algorithms',
            'SEO Content Creation',
            'Meta Tags Optimization',
            'SEO Friendly URLs',
            'Importance of SSL for SEO',
            'Mobile Optimization and SEO',
            'Image Optimization for SEO',
            'Structured Data and SEO',
            'User Experience (UX) in SEO',
            'SEO Audit Checklist',
            'Google Analytics for SEO',
            'SERP Features and SEO',
            'Voice Search Optimization',
            'SEO vs. SEM Differences',
            'Canonical Tags and SEO',
            'Pagination and SEO Best Practices',
            'SEO Compliance and Guidelines',

            'SEO Introduction and Definitions',
            'Importance of SEO for Online Visibility',
            'Understanding Search Engine Algorithms',
            'SEO vs SEM: Differences and Synergies',
            'Core Components of SEO Strategy',
            'How Search Engines Work',
            'White Hat vs Black Hat SEO Techniques',
            'The Role of Content in SEO',
            'Mobile SEO and Responsive Design',
            'Site Architecture and SEO Siloing',
            'The Evolution of SEO Practices',
            'Domain Authority and PageRank Explained',
            'Crawling, Indexing, and Ranking Basics',
            'User Experience (UX) and SEO',
            'HTTPS and Secure Websites Impact on SEO',
            'Voice Search Optimization',
            'Local SEO Fundamentals',
            'International SEO Considerations',
            'SEO for E-commerce Websites',
            'The Future of SEO and Emerging Trends',
        ],
    },
    {
        id: 2,
        imageUrl: '/logo192.png',
        urlSlug: 'keyword-research',
        subject: 'Keyword Research',
        disabled: true,
        description: 'Discover effective strategies for conducting keyword research and analysis.',
        subTopics: [
            'Long-Tail Keywords vs Short-Tail Keywords',
            'Competitor Keyword Analysis',
            'Keyword Difficulty and Ranking Potential',
            'Search Intent and User Behavior',
            'Seasonal Keyword Trends and Analysis',
            'Localized Keyword Research',
            'Using Google Keyword Planner',
            'LSI Keywords and Semantic Search',
            'Integrating Keywords into Content Strategy',
            'Keyword Optimization for Voice Search',
            'The Role of AI in Keyword Research',
            'Analyzing Keyword Search Volume',
            'Content Gap Analysis for Keyword Opportunities',
            'SERP Feature Targeting with Keywords',
            'Keyword Clustering for Topic Authority',
            'Tools for Advanced Keyword Research',
            'Keyword Metrics to Monitor Success',
            'Balancing Keyword Density and Readability',
            'Mobile Keywords vs Desktop Keywords',
            'Tracking and Adapting to Keyword Trends',
        ],
    },
    {
        id: 3,
        imageUrl: '/logo192.png',
        urlSlug: 'on-page-seo',
        subject: 'On-Page SEO',
        disabled: true,
        description:
            'Explore best practices for optimizing web pages to improve search engine rankings.',
        subTopics: [
            'Title Tag Optimization',
            'Meta Description Crafting',
            'Heading Tags Hierarchy',
            'Internal Linking Strategy',
            'Content Quality and Relevance',
            'Keyword Density and Placement',
            'LSI Keywords Integration',
            'Page Loading Speed Improvement',
            'Mobile-Friendly Design Implementation',
            'Alt Text for Images',
            'Schema Markup Application',
            'Social Sharing Buttons Inclusion',
            'URL Structure Best Practices',
            'Content Freshness and Updates',
            'Duplicate Content Handling',
            'Crawlability and Indexability Enhancements',
            'AMP Implementation for SEO',
            'Click-Through Rate Optimization',
            'Engagement Metrics Impact on SEO',
            'Conversion Rate Optimization and SEO',
            'Title Tag Optimization',
            'Meta Description Crafting',
            'Headings and Subheadings Usage',
            'URL Structure Best Practices',
            'Image Alt Text and Optimization',
            'Internal Linking Strategies',
            'Optimizing Page Load Speed',
            'Content Quality and Relevance',
            'Keyword Placement and Frequency',
            'Schema Markup Implementation',
            'Mobile Optimization for On-Page Elements',
            'Rich Snippets and Featured Snippets Optimization',
            'Canonical Tags and Managing Duplicate Content',
            'Social Media Tags for SEO (Open Graph, Twitter Cards)',
            'Core Web Vitals Optimization',
            'Above-the-Fold Content Optimization',
            'SEO-Friendly Content Formats',
            'User Engagement Metrics Improvement',
            'CTR Optimization with SERP Features',
            'A/B Testing for On-Page SEO Elements',
        ],
    },
    {
        id: 4,
        imageUrl: '/logo192.png',
        urlSlug: 'link-building',
        subject: 'Link Building',
        disabled: true,
        description:
            'Learn proven techniques for acquiring high-quality backlinks to boost SEO performance.',
        subTopics: [
            'Guest Blogging Outreach',
            'Broken Link Building',
            'Skyscraper Technique',
            'Resource Page Backlinking',
            'Competitor Backlink Analysis',
            'Influencer Collaboration and Mentions',
            'Content Syndication Networks',
            'HARO (Help A Reporter Out) for Backlinks',
            'Directory Submission Strategy',
            'Niche Forum Participation',
            'Social Media Profile Links',
            'Local Citation Building',
            'Testimonial Link Building',
            'Scholarship Link Building',
            'PBNs (Private Blog Networks) Risks',
            'Link Reclamation from Mentioned Brands',
            'Video Marketing and Backlinks',
            'Infographic Creation and Distribution',
            'Press Release for High-Quality Backlinks',
            'Link Exchange Ethics and Best Practices',

            'High-Quality Backlink Acquisition',
            'Guest Blogging for Backlinks',
            'Broken Link Building Technique',
            'Skyscraper Link Building Strategy',
            'Social Media Signals and SEO',
            'Influencer Outreach for Link Building',
            'Content Marketing for Link Attraction',
            'Local Citations and NAP Consistency',
            'Creating Shareable Infographics',
            'Directory Submission Best Practices',
            'Forum Participation and Signature Links',
            'Brand Mentions and Non-Linked Citations',
            'Competitor Backlink Analysis',
            'HARO (Help a Reporter Out) for Backlinks',
            'Resource Page Link Building',
            'Link Reclamation and Updating Old Links',
            'Link Diversity and Anchor Text Optimization',
            'Building Relationships with Webmasters',
            'Sponsored Content and NoFollow Links',
            'Monitoring and Disavowing Toxic Links',
        ],
    },
    {
        id: 5,
        imageUrl: '/logo192.png',
        urlSlug: 'seo-content-writing',
        subject: 'SEO Content Writing',
        disabled: true,
        description:
            'Master the art of creating SEO-friendly content that engages readers and ranks well in search results.',
    },
    {
        id: 6,
        imageUrl: '/logo192.png',
        urlSlug: 'technical-seo',
        subject: 'Technical SEO',
        disabled: true,
        description:
            'Understand the technical aspects of SEO and optimize your website for better search engine visibility.',
    },
    {
        id: 7,
        imageUrl: '/logo192.png',
        urlSlug: 'local-seo',
        subject: 'Local SEO (GMB)',
        disabled: true,
        description:
            'Learn how to optimize your website for local search and leverage Google My Business (GMB) for better visibility in local listings.',
        subTopics: [
            'Google My Business Optimization',
            'Local Keywords Research',
            'NAP Consistency Checks',
            'Local Citations and Directories',
            'Online Reviews and Reputation Management',
            'Localized Content Creation',
            'Schema Markup for Local Businesses',
            'Hyperlocal SEO Strategies',
            'Mobile Optimization for Local Search',
            'Local Backlink Building',
            'Localized Meta Tags',
            'Google Maps SEO Tactics',
            'Local Press Release Distribution',
            'User Experience for Local Sites',
            'Voice Search for Local SEO',
            'Local Event Marketing and SEO',
            'Geotargeting and Geo-specific Content',
            'Local Social Media Engagement',
            'Location Pages for Multi-location Businesses',
            'Competitor Analysis in Local SEO',
        ],
    },
    {
        id: 8,
        imageUrl: '/logo192.png',
        urlSlug: 'seo-tools',
        subject: 'SEO Tools and Software',
        description:
            'Explore the best SEO tools and software to enhance your digital marketing strategies.',
        subTopics: [
            'Google Analytics for Traffic Analysis',
            'Google Search Console for Performance Tracking',
            'SEMrush for Competitive Intelligence',

            'Google Analytics for Traffic Analysis',
            'Google Search Console for Performance Tracking',
            'SEMrush for Competitive Intelligence',
            'Ahrefs for Backlink Research',
            'Moz Pro for SEO Monitoring',
            'Yoast SEO for WordPress Optimization',
            'Screaming Frog for Site Crawling',
            'Majestic for Link Analysis',
            'BuzzSumo for Content Insights',
            'Ubersuggest for Keyword Discovery',
            'SpyFu for PPC and SEO Research',
            'Serpstat for SERP Analysis',
            'CognitiveSEO for Signal Tracking',
            'KWFinder for Long-Tail Keywords',
            'Raven Tools for Reporting and Audits',
            'SEO PowerSuite for All-in-One SEO',
            'DeepCrawl for Website Architecture Analysis',
            'Cora for Advanced SEO Diagnostics',
            'Pitchbox for Influencer Outreach',
            'GTmetrix for Page Speed Insights',

            'Google Analytics for SEO Tracking',
            'Google Search Console Insights',
            'SEMrush for Competitor Analysis',
            'Ahrefs for Backlink Profiling',
            'Moz Pro for SEO Metrics',
            'Screaming Frog for Site Audits',
            'Majestic for Link Intelligence',
            'Yoast SEO for WordPress Optimization',
            'Ubersuggest for Keyword Discovery',
            'BuzzSumo for Content Research',
            'SpyFu for PPC and SEO Research',
            'Serpstat for Search Analytics',
            'CognitiveSEO for Signal Analysis',
            'KWFinder for Long-Tail Keywords',
            'GTmetrix for Website Speed Analysis',
            'SEO PowerSuite for All-in-One SEO',
            'Raven Tools for SEO Reports',
            'Google Data Studio for Custom Reporting',
            'Advanced Web Ranking for SERP Tracking',
            'AnswerThePublic for Consumer Insight',

            'best ai seo tools',
            'best seo tools for small businesses',
            'best seo reporting tools',
            'best enterprise seo tools',
            'best seo tools for small business',
        ],
    },
    {
        id: 9,
        imageUrl: '/logo192.png',
        subject: 'Case Studies',
        urlSlug: 'case-studies',
        description:
            'Learn how our clients are using our platform to scale their content creation efforts.',
    },
    {
        name: 'AI SEO',
        disabled: true,
        description:
            'Discover how artificial intelligence is revolutionizing the field of SEO and digital marketing.',
        subTopics: [
            'AI-Powered SEO Tools',
            'Machine Learning Algorithms in SEO',
            'Natural Language Processing (NLP) in SEO',
            'AI Content Generation and Optimization',
            'Predictive Analytics for SEO',
            'AI SEO Strategy Development',
            'AI SEO Case Studies and Success Stories',
            'The Future of AI in SEO',
            'AI SEO vs Traditional SEO',
            'Ethical Considerations in AI SEO',
            'AI SEO Implementation Challenges',
            'AI SEO Training and Certification',
            'AI SEO for Customer Satisfaction Surveys',
        ],
    },
    // Content Optimization
    // Content Marketing Strategy
    // Research and Case Studies
    // Content Management
];

export const availableTabs: ArticlePrefix[] = ['blog'];

const appCategories: any = {
    subjects: subjects.filter((subject: any) => !subject.disabled),
};

export default appCategories;
