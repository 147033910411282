'use client';

import * as React from 'react';
import { Box, Button, FeaturedImage, Link, TopMenu, TopMenuDropdown, TopMenuDropdownContent, TopMenuDropdownLayout } from '@repo/ui';
import Image from 'next/image';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SourceIcon from "@mui/icons-material/Source"
import TocIcon from '@mui/icons-material/Toc';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import KeyIcon from '@mui/icons-material/Key';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ApartmentIcon from '@mui/icons-material/Apartment';
import TopMenuRightSide from '@/components/organisms/TopMenu/TopMenuRightSide';
import TopMenuPaymentNeeded from '@/components/organisms/TopMenu/TopMenuPaymentNeeded';
import appCategories from '@/constants/categories';
import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';
import { defaultSeo } from '@/constants/seo-constants';

export const topmenuItems = [
    // {
    //     title: 'Product',
    //     icon: <HistoryEduIcon fontSize='large' />,
    //     columns: [
    //         {
    //             title: 'Features',
    //             items: [
    //                 {
    //                     title: 'MindLess AI',
    //                     description: 'Generate SEO optimized content that ranks in minutes',
    //                     icon: <FeaturedImage Image={Image} src="/logo192.png" width={35} height={35} alt='MindLess AI' lazyLoad={false} />,
    //                     url: '/ai',
    //                 },
    //                 {
    //                     title: 'Content Editor',
    //                     description: 'Write smarter and faster with NLP-based recommendations',
    //                     icon: <HistoryEduIcon fontSize='large' />,
    //                     url: '/content-editor',
    //                 },
    //                 {
    //                     title: 'Project Audit',
    //                     description: 'Get a detailed analysis of your website for SEO issues',
    //                     icon: <VerifiedUserIcon fontSize='large' />,
    //                     highlightText: 'New',
    //                     url: '/project-audit',
    //                 },
    //                 {
    //                     title: 'Keyword Research',
    //                     description: 'Find the best keywords for your content clustered by topical relevance',
    //                     icon: <KeyIcon fontSize='large' />,
    //                     disabled: true,
    //                     url: '/keyword-research',
    //                 }
    //             ]
    //         },
    //         {
    //             title: 'Free Tools',
    //             items: [
    //                 {
    //                     title: 'Outline Generator',
    //                     description: 'Generate a content outline in seconds that your audience and Google will love',
    //                     url: '/free-outline-generator',
    //                     icon: <TocIcon fontSize='large' />,
    //                     disabled: true,
    //                 },
    //                 {
    //                     title: 'SERP Analyzer',
    //                     description: 'Check your rankings and analyze your competitors in one click',
    //                     url: '/free-serp-checker',
    //                     icon: <AutoGraphIcon fontSize='large' />,
    //                     disabled: true,
    //                 },
    //                 {
    //                     title: 'SEO Sitemap Finder',
    //                     description: 'Find sitemaps of any website in seconds',
    //                     url: '/free-seo-sitemap-finder',
    //                     icon: <AccountTreeIcon fontSize='large' />,
    //                     disabled: true,
    //                 }
    // convert html to markdown and vice versa
    //             ]
    //         },
    //     ],
    // },
    {
        title: 'Solutions',
        icon: <EmojiObjectsIcon fontSize='large' />,
        columns: [
            {
                items: [
                    {
                        title: 'For Marketing teams',
                        url: '/solutions/marketing-teams',
                    },
                    {
                        title: 'For Agencies',
                        url: '/solutions/agencies',
                    },
                    {
                        title: 'For Individual Writers',
                        url: '/solutions/individual-writers',
                    },
                    {
                        title: 'For Large Site Publishers',
                        url: '/solutions/large-site-publishers',
                    }
                ],
            },
        ],
    },
    {
        title: 'Resources',
        icon: <SourceIcon fontSize='large' />,
        columns: [
            {
                items: [
                    {
                        title: 'Blog',
                        description: 'Read our latest articles',
                        url: '/blog',
                    },
                    {
                        title: 'Case Studies',
                        description: 'Learn how our customers use our products',
                        url: '/case-studies/blog',
                    },
                    // {
                    //     title: 'Help Center',
                    //     description: 'Find answers to your questions',
                    //     url: '/help-center',
                    // }
                ],
            },
        ],
    },
    {
        title: 'Company',
        icon: <ApartmentIcon fontSize='large' />,
        columns: [{
            items: [
                {
                    title: 'About Us',
                    description: 'Learn more about us',
                    url: '/about',
                },
                {
                    title: 'Contact Us',
                    description: 'Get in touch with us',
                    url: '/contact',
                }
            ],
        },
        ],
    },
    {
        title: "Pricing",
        url: "/pricing",
        icon: <MonetizationOnIcon fontSize='large' />,
    }
]

const TopMenuWrapper = () => {
    const { user } = useUserStore();
    const paymentNeeded = user.paymentStatus === 'PAYMENT_NEEDED';

    return (
        <TopMenu
            companyName={defaultSeo.name}
            Image={Image}
            hasBanner={paymentNeeded}
            appCategories={appCategories}
            TopMenuRightSide={TopMenuRightSide}
            TopMenuBanner={TopMenuPaymentNeeded}
            customLeftSide={
                <Box display='flex'>
                    {topmenuItems.map((item, index) => (
                        <span key={index}>
                            {item.columns ?
                                <TopMenuDropdown
                                    placeholder={item.title}
                                    dropdownMenuContent={
                                        <TopMenuDropdownLayout>
                                            <TopMenuDropdownContent columns={item.columns} />
                                        </TopMenuDropdownLayout>
                                    }
                                />
                                : <Link to={item.url}>
                                    <Button padding='1rem .75rem' variant="text">{item.title}</Button>
                                </Link>
                            }
                        </span>
                    ))}
                </Box>
            }
        />
    );
};

export default TopMenuWrapper;
