"use client"

import { Button, Box, NotificationsTopMenu } from "@repo/ui"
import * as React from "react"
import { useWindowSize } from "@repo/utils/hooksUtils"
import { useUserStore } from "@/store/mutable-data/user/UserStoreContext"
import TopMenuMobile from "@/components/organisms/TopMenu/TopMenuRightSide/TopMenuMobile"
import UserTopMenu from "@/components/organisms/TopMenu/UserTopMenu"
import RedirectBackLinkWrapper from "@/components/molecules/RedirectBackLinkWrapper"
import RegisterActionButton from "@/app/(public)/(payment)/pricing/RegisterActionButton"
import styles from "./TopMenuRightSide.module.scss"

const TopMenuRightSide = () => {
  const { user, setUserState } = useUserStore()
  const loggedIn = Boolean(user.id)

  const { width } = useWindowSize()

  if (width <= 650) {
    return <TopMenuMobile userLoggedIn={loggedIn} user={user} setUserState={setUserState} />
  }

  if (loggedIn) {
    return (
      <Box className={styles["right-side-actions-desktop"]}>
        <Box style={{ display: "flex", alignItems: "center", textAlign: "center" }} margin="0 0.5rem 0 0">
          <NotificationsTopMenu />
        </Box>

        <UserTopMenu user={user} setUserState={setUserState} />
      </Box>
    )
  }

  return (
    <Box>
      <RedirectBackLinkWrapper url="/login">
        <Button margin="0 1rem 0 0" variant="">Login</Button>
      </RedirectBackLinkWrapper>

      <RegisterActionButton
        color="secondary"
        marginTop="2rem"
        text="Get 2 Free Articles"
        nextUrl="/projects"
      />
    </Box>
  )
}

export default TopMenuRightSide
